<template>
	<div class="full-height flex-column size-px-14">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="goBack"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ item_vote.cartl_vote_base_info.vote_title }} &gt; {{ item.optiop_title }}</h2>
			</div>
		</div>
		<div class="mt-50">
			<div
				v-if="item.optiop_contents_use_fg == 'Y'"
				class="pa-20"
			>
				<div
					v-if="item.optiop_appendix_file_code == 'CA03900001'"
				>
					<div class="radius-20">
						<img
							v-for="(file, f_index) in item.optiop_file_thumbnail_img.optiop_img_partial_list"
							:key="'file_' + f_index"
							:src="file.optiop_file_thumbnail_img_url"
							@error="$bus.$emit('onErrorImage', $event)"
							class="width-100"
							:class="{'object-cover': item.optiop_file_thumbnail_img.optiop_img_partial_list.length == 1}"
						/>
					</div>
				</div>
				<div
					v-else-if="item.optiop_appendix_file_code == 'CA03900003'"
				>
					<video-embed
						:src="item.optiop_file_url"

						width="100%"
					></video-embed>
				</div>

				<div class="mt-20">
					<div class="justify-space-between">
						{{ item.optiop_title }}
						<div class="color-red"><img :src="require('@/assets/image/icon_voting_count_red.svg')" /> {{ item.optiop_participation_member_count | makeComma }}</div>
					</div>
					<div class="mt-15">

						<a
							v-for="(social, s_index) in item.optiop_social_info_list"
							:key="'social_' + s_index"
							class="inline-block square-38"

							:href="'https://' + social.lnk_url.replace('https://', '')" target="_blank"
						><img :src="social.system_img_url" class="width-100" /></a>

					</div>
					<div class="mt-15">
						{{ item.optiop_contents }}
					</div>
				</div>
			</div>

			<hr
				v-if="item.optiop_contents_use_fg == 'Y' && item_vote.cartl_vote_base_info.anoyms_vote_fg == 'N'"
				class="top-line mt-20 mb-10"
			/>

			<div
				v-if="item_vote.cartl_vote_base_info.anoyms_vote_fg != 'Y'"
				class="pa-10-20 mb-50"
			>
				<div> {{ $language.voting.title_voting_count }} <!-- 투표 참여자 --> {{ item.optiop_participation_member_count | makeComma}}명</div>
				<div class="mt-20 ">
					<button
						class="mr-10 box radius-20 pa-5-10"
						:class="optiop_participation_member_list_sort_code == 'CA04000001' ? 'bg-sort-on' : 'bg-sort-none'"
						@click="setSort('CA04000001')"
					>{{ $language.voting.txt_voting_order1}}</button>

					<button
						class="mr-10 box radius-20 pa-5-10"
						:class="optiop_participation_member_list_sort_code == 'CA04000002' ? 'bg-sort-on' : 'bg-sort-none'"
						@click="setSort('CA04000002')"
					>{{ $language.voting.txt_voting_order2}}</button>
				</div>

				<ul
					v-if="item.optiop_participation_member_list.length > 0"
					class="mt-20"
				>
					<li
						v-for="(member, m_index) in item.optiop_participation_member_list"
						:key="'member_' + m_index"
						class="mt-20"
					>
						<div class="justify-space-between gap-10">
							<ProfileImage
								:user="user"
								:item_info="member"
								:cartel_info="item_cartel"
								icon_type="m"
								:is_nickname="true"
							></ProfileImage>

							<div class="flex-1 size-px-13 color-gray">
								<div class="size-px-14 font-weight-500 color-333">{{ member.nickname }}</div>
								<div>{{ member.member_grade_name }}</div>
								<div>{{ member.vote_daytime }}</div>
							</div>
						</div>
					</li>
				</ul>

				<Empty
					v-else
				></Empty>
			</div>
		</div>

	</div>
</template>
<script>
	import ProfileImage from "@/components/Daum/ProfileImage";
	import Empty from "@/view/Layout/Empty";
	export default {
		name: 'CartelVotingItemInfo'
		,
		components: {Empty, ProfileImage},
		props: ['user']
		, data: function(){
			return {

				program: {
					title: this.$language.voting.title_voting_new
					, name: this.$language.voting.title_voting_new
					, type: 'cartel_sub'
					, not_header: true
					, not_footer: true
				}
				, item_voting: {

				}
				, item_vote: {
					cartl_vote_base_info: {

					}
				}
				, item: {
					optiop_participation_member_list: []
				}
				, item_search: {
					page: 1
					, list_cnt: 10
				}
				, item_cartel: {

				}
				, optiop_participation_member_list_sort_code: 'CA04000001'
			}
		}
		, methods: {
			getData: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_vote_item
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
							, cartl_vote_optiop_number: this.$route.params.o_id
							, optiop_participation_member_list_sort_code: this.optiop_participation_member_list_sort_code // CA04000001 시간순 CA04000002 참여자순
							, anoyms_process_character: '*'
						}
						, type: true
					})
					if (result.success) {
						this.item = result.data
						this.$set(this.item, 'cartl_vote_optiop_list', result.data.cartl_vote_optiop_list)

					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, getVote: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_voting_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
						}
						, type: true
					})
					if (result.success) {
						this.item_vote = result.data
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, getVotingInfo: async function() {
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_voting_info_user
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
						}
						, type: true
					})
					if (result.success) {
						this.item_voting = result.data
						switch (this.item_voting.requester_cartl_member_grade_code) {
							case 'CA02500001':
								break
							case 'CA02500002':
								break
							case 'CA02500003':
								if (this.item_voting.vote_release_settup_fg != 'Y') {
									this.$emit('goBack')
								}
								break
						}
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, goBack: function(){
				this.$emit('goBack')
			}
			, getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
							, post_list_page: { page_number: 1, pagerecnum: 10}
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, setSort: function(type){
				this.optiop_participation_member_list_sort_code = type
				this.getData()
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getVotingInfo()
			this.getVote()
			this.getData()
		}
	}
</script>

<style>
	.bg-sort-on {
		background: #FDD4D7;
		color: #DC505C;
		border-color: #FDD4D7;
	}
	.bg-sort-none {

		background: var(--light-Gray01);
		color: #434343;
		border-color: var(--light-Gray01);
	}


	iframe {
		width: 100%;
		border: none;
		height: 300px;
	}
</style>